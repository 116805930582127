body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.formHeader {
  background-color: #0072BC;
  color: white;
  padding: 4px;
  text-align: center;
}

.welcomePage {
  max-width: 750px;
  margin: auto;
  text-align: center;
}

.formList {
  background-color: #E2E2E2;
  padding: 24px 0px 24px 0px;
}

.signatureCanvas {
  background-color: white;
  border: 1px dashed black;
  width: 100%;
  min-height: 150px;
  max-height: 150px;
}

.signBox {
  background-color: #eee;
  margin-bottom: 8px;
  padding: 8px;
}

.nothingBox {
  border: 1px dashed black;
  background-color: #eee;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
}

.errorText {
  color: red;
}

.consentText {
  background-color: white;
  border-style: ridge;
  padding: 8px;
  width: 100%;
}