.fp17-header {
    padding-bottom: 6px;
    font-weight: bold;
}

.fp17-section {
    border-top: 1px solid #2672BC;
    margin-top: 6px;
    padding-top: 6px;
}

.fp17-highlight {
    border: 2px solid #2672BC;
    background-color: #2671bc48;
    margin-top: 6px;
    padding: 6px;
}

.input-column * {
    width: 100%;
}

.input-fullwidth {
    width: 100%;
}

.field-error {
    color: red;
    font-size: small;
    padding-bottom: 0px;
}